import Splide from '@splidejs/splide';

document.addEventListener('DOMContentLoaded', function () {
  document.querySelectorAll('.splide_1').forEach(function (splide) {
    const splideElement = new Splide(splide, {
      autoplay: {
        delay: 1,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
      perPage : 1,
      interval: 3000,
      type: 'loop',
      pagination: false,
      arrows: false,
    });

    splideElement.mount();

    // Запускаем, обязательно
    const { Autoplay } = splideElement.Components;
    Autoplay.play();
  });

  document.querySelectorAll('.splide_3').forEach(function (splide) {
    const splideElement = new Splide(splide, {
      type: 'loop',
      drag: 'free',
      perPage: 1,
      arrows: false,
      pagination: true,
    });

    splideElement.mount();
  });
});
